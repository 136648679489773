import React from 'react';
import PropTypes from 'prop-types';
import { transparentize } from 'polished';
import styled from 'styled-components';

import Container from './Container';
import FooterContact from './FooterContact';
import SocialLinks from './SocialLinks';
import Link from './Link';

const StyledFooter = styled.footer`
  padding: 2rem 0;
  background-color: ${props => props.theme.footer.backgroundColor};
  color: ${props => props.theme.colors.white};

  a {
    color: inherit;
  }
`;

const Copyright = styled.p`
  margin: 0;
  color: ${props => transparentize(0.4, props.theme.colors.white)};
  text-align: center;
`;

const Footer = ({
  title, socialNetworks, email, phone,
}) => (
  <StyledFooter>
    <Container>
      <FooterContact email={email} phone={phone} />
      <SocialLinks links={socialNetworks} color="white" />
      <Copyright>
        {title}
        &nbsp;&middot; Všechna práva vyhrazena © 2019
        &nbsp;&middot;
        {' '}
        <Link to="/vseobecne-podminky/">Všeobecné podmínky</Link>
        &nbsp;&middot;
        {' '}
        <Link to="/recenze/">Napsat recenzi</Link>
      </Copyright>
    </Container>
  </StyledFooter>
);

Footer.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Footer;
