import React from 'react'
import { Box } from 'rebass'

// import AmazingPlacesWinner from "../images/AP_Member_digital_2021_CZ.png";

const AmazingPlaces = () => (
  <Box
    sx={{
      p: 4,
      display: 'grid',
      gap: 4,
      gridAutoFlow: ['row', 'column'],
      justifyContent: 'center',
      alignItems: ['center', 'center'],
    }}
  >
    <a
      href="https://www.amazingplaces.cz/cs/kouzelna-mista/chaloupka-ktera-existuje-v-poli-463"
      target="_blank"
    >
      <img
        src="/amazing-places-2024.svg"
        alt=""
        style={{
          width: '100%',
          maxWidth: '290px',
          height: '135px',
          userSelect: 'none',
        }}
      />
      {/* <img src={AmazingPlacesMember} alt="" style={{ maxWidth: '290px' }} /> */}
      {/* <img src={AmazingPlacesWinner} alt="" style={{ maxWidth: "290px" }} /> */}
    </a>
  </Box>
)

export default AmazingPlaces
