import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import FacebookIcon from '../../images/facebook.inline.svg';
import InstagramIcon from '../../images/instagram.inline.svg';

const StyledSocialLinks = styled.ul`
  list-style: none;
  display: flex;
  justify-content: ${props => props.justifyContent || 'center'};
  margin: 0 0 1rem;
  padding: 0;
`;

const StyledLink = styled(OutboundLink)`
  display: inline-block;
  padding: 0.5rem;

  svg {
    max-width: ${props => props.iconSize || '24px'};
    max-height: ${props => props.iconSize || '24px'};
    color: ${props => transparentize(0.2, props.color === 'white' ? props.theme.colors.white : props.theme.colors.text)};
    fill: currentColor;
    transition: ${props => props.theme.transitions.default};
  }

  &:focus,
  &:hover {
    svg {
      color: ${props => (props.color === 'white' ? props.theme.colors.white : props.theme.colors.text)};
    }
  }
`;

const getIcon = (icon) => {
  switch (icon.value) { // icon.value is from wordpress acf options
    case 'facebook':
      return <FacebookIcon />;
    case 'instagram':
      return <InstagramIcon />;
    default:
      return null;
  }
};

const SocialLinks = props => (
  <StyledSocialLinks justifyContent={props.justifyContent || 'center'}>
    {props.links.map(link => (
      <li key={link.url}>
        <StyledLink
          href={link.url}
          title={link.name}
          target="_blank"
          rel="noopener noreferrer"
          color={props.color || 'white'}
          iconSize={props.iconSize || '24px'}
          justifyContent="flex-start"
        >
          {getIcon(link.icon)}
        </StyledLink>
      </li>
    ))}
  </StyledSocialLinks>
);


export default SocialLinks;
