import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import skewedLines from "../images/skewed-lines.png";

const StyledNavigation = styled.ul`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  margin: 0;
  margin-top: 1rem;
  padding: 0;
  list-style: none;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    display: flex;
    flex-direction: row;
    flex-basis: auto;
    margin-top: 0;
  }
`;

const StyledLink = styled(Link)`
  padding: 0.5rem 0;
  display: block;
  font-size: 1rem;
  text-decoration: none;
  white-space: nowrap;
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 1rem;
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    background-color: ${(props) => props.theme.colors.accent};
    background-image: url(${skewedLines});
    background-repeat: repeat-x;
    background-size: 5px 4px;
    transform: scale3d(0, 1, 1);
    transform-origin: 0 50%;
    transition: transform 0.2s ease-in-out;
  }

  &:focus,
  &:hover,
  &.active {
    &:after {
      transform: scale3d(1, 1, 1);
    }
  }
`;

const Navbar = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              id
              name
              items {
                wordpress_id
                order
                title
                url
                object
                object_id
                object_slug
                attr
                target
                description
              }
            }
          }
        }
        allWordpressCategory {
          edges {
            node {
              id
              name
              slug
              wordpress_id
            }
          }
        }
      }
    `}
    render={(data) => {
      const menuItems =
        data.allWordpressWpApiMenusMenusItems.edges[0].node.items;
      const { edges: categories } = data.allWordpressCategory;

      return (
        <StyledNavigation isOpen={props.isOpen}>
          {menuItems.map((item) => {
            switch (item.object) {
              case "custom":
                if (item.url.indexOf("http") === 0) {
                  return (
                    <li key={item.object_slug}>
                      <StyledLink
                        as="a"
                        className="nav-link"
                        activeClassName="active"
                        href={item.url}
                        target={item.target}
                      >
                        {item.title}
                      </StyledLink>
                    </li>
                  );
                }
                return (
                  <li key={item.object_slug}>
                    <StyledLink
                      className="nav-link"
                      activeClassName="active"
                      to={item.url}
                      state={{ scrollToReservation: false }}
                    >
                      {item.title}
                    </StyledLink>
                  </li>
                );
              case "category": {
                const cat = categories.find(
                  (category) => item.object_id === category.node.wordpress_id
                );
                return (
                  <li key={item.object_slug}>
                    <StyledLink
                      className="nav-link"
                      activeClassName="active"
                      to={`/kategorie/${cat.node.slug}/`}
                    >
                      {item.title}
                    </StyledLink>
                  </li>
                );
              }
              case "mphb_room_type": {
                return (
                  <li key={item.object_slug}>
                    <StyledLink
                      className="nav-link"
                      activeClassName="active"
                      to={`/chaloupky/${item.object_slug}/`}
                    >
                      {item.title}
                    </StyledLink>
                  </li>
                );
              }
              case "mphb_room_service": {
                return (
                  <li key={item.object_slug}>
                    <StyledLink
                      className="nav-link"
                      activeClassName="active"
                      to="/nase-sluzby/"
                    >
                      {item.title}
                    </StyledLink>
                  </li>
                );
              }
              case "mphb_room_service": {
                return (
                  <li key={item.object_slug}>
                    <StyledLink
                      className="nav-link"
                      activeClassName="active"
                      to="/nase-sluzby/"
                    >
                      {item.title}
                    </StyledLink>
                  </li>
                );
              }
              default:
                if (item.object_slug === "cenik") {
                  return (
                    <li>
                      <StyledLink
                        className="nav-button"
                        to="/chaloupky"
                        state={{ linkToPricelist: true }}
                      >
                        Ceník
                      </StyledLink>
                    </li>
                  );
                }
                return (
                  <li key={item.object_slug}>
                    <StyledLink
                      className="nav-link"
                      activeClassName="active"
                      to={`/${item.object_slug}/`}
                      state={{ scrollToReservation: false }}
                    >
                      {item.title}
                    </StyledLink>
                  </li>
                );
            }
          })}
          <li>
            <StyledLink
              className="nav-button"
              to="/chaloupky"
              state={{ scrollToReservation: true }}
            >
              Rezervace
            </StyledLink>
          </li>
        </StyledNavigation>
      );
    }}
  />
);

export default Navbar;
