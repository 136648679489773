import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import GlobalStyle from '../styles/globalStyle';
import theme from '../styles/theme';

import Header from './Header';
import AmazingPlaces from './AmazingPlaces';
import Footer from './Footer';

const TemplateWrapper = ({ children, data, ishome = false }) => {
  const { title } = data.site.siteMetadata;
  const { social_network: socialNetworks } = data.allWordpressAcfOptions.edges[0].node.options;
  const { e_mail: email, phone } = data.allWordpressAcfOptions.edges[0].node.options;

  return (
    <ThemeProvider theme={theme}>
      <>
        <Normalize />
        <GlobalStyle />
        <Helmet>
          <html lang="cs" />
          <title>{title}</title>
        </Helmet>

        <Header title={title} data={data} ishome={ishome} />

        <>{children}</>

        <AmazingPlaces />

        <Footer title={title} socialNetworks={socialNetworks} email={email} phone={phone} />
      </>
    </ThemeProvider>
  );
};

const TemplateWrapperQuery = props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        allWordpressAcfOptions {
          edges {
            node {
              id
              wordpress_id
              options {
                e_mail
                phone
                social_network {
                  name
                  icon {
                    label
                    value
                  }
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <TemplateWrapper data={data} {...props} />}
  />
);

export default TemplateWrapperQuery;
