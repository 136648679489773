import { darken } from 'polished';

const white = '#ffffff';
const green = '#8dc63f';
const darkGreen = '#234047';
const brown = '#bd9b6a';

export default {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  header: {
    height: '6rem',
    padding: {
      vertical: '0.75rem',
    },
  },
  footer: {
    backgroundColor: darken(0.1, darkGreen),
  },
  typography: {
    lineHeight: 1.5,
  },
  forms: {
    borderColor: '#b0b0b0',
    fontSize: '1rem',
  },
  colors: {
    white,
    brown,
    text: darkGreen,
    accent: green,
    background: white,
    blue: '#07c',
    lightgray: '#f6f6ff',
    red: '#C63F4A',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    sans: "'Barlow', system-ui, sans-serif",
    mono: 'Menlo, monospace',
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  transitions: {
    default: 'all 0.2s ease-in-out',
  },
  variants: {
    quotation: {
      maxWidth: '40rem',
      p: 4,
      boxShadow: 'large',
    },
  },
};
