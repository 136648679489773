import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Obfuscate from 'react-obfuscate';

const StyledFooterContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;

  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    flex-direction: row;
  }
`;

const StyledLink = styled(Obfuscate)`
  margin: 0 0.5rem 1rem;
  padding: 0.75rem 1rem;
  background-color: ${props => props.theme.footer.backgroundColor};
  border: 1px solid #fff;
  text-decoration: none;
  transition: ${props => props.theme.transitions.default};
  white-space: nowrap;

  &:focus,
  &:hover {
    background-color: #fff;
    color: ${props => props.theme.colors.text};
  }

  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    margin-bottom: 0;
  }
`;

const FooterContact = ({ email, phone }) => (
  <StyledFooterContact>
    {phone && <StyledLink tel={phone.replace(/[- ]/g, '')}>{phone}</StyledLink>}
    {email && <StyledLink email={email} />}
  </StyledFooterContact>
);

FooterContact.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
};

FooterContact.defaultProps = {
  email: null,
  phone: null,
};

export default FooterContact;
