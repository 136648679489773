import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const SiteLogo = styled.img`
  max-height: 5rem;
  display: block;
`;

const Logo = ({
  logo, title, data,
}) => (
  <Link to="/">
    {logo ? (
      <SiteLogo className="site-logo" src={logo} alt={title} />
    ) : (
      <Img className="site-logo" fixed={data.file.childImageSharp.fixed} alt={title} />
    )}
  </Link>
);

Logo.propTypes = {
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default Logo;
