import styled from 'styled-components';

const maxWidth = 1040 / 16;

const Container = styled.div`
  max-width: ${props => (props.narrow ? '640px' : `${maxWidth}rem`)};
  margin: auto;
  box-sizing: content-box;

  @media screen and (max-width: ${maxWidth + 2}rem) {
    padding: 0 1rem;
  }
`;

export default Container;
