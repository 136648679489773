import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import Headroom from 'react-headroom';
import Container from './Container';
import Logo from './Logo';
import Navbar from './Navbar';
import logo from '../images/chaloupka-logo-hero.svg';

const headroomBoxShadow = '0 1px 8px rgba(0, 0, 0, 0.05)';

const StyledHeadroom = styled(Headroom)`
  position: relative;
  z-index: 10;
  height: ${props => (props.ishome ? '0 !important' : `calc(${props.theme.header.height} + 0.75rem) !important`)};

  & .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: ${rgba('#fff', 0.9)};
    transition: background-color 200ms ease-in-out, box-shadow 0.3s;
    box-shadow: ${props => (props.isMenuOpen ? headroomBoxShadow : 'none')};
  }

  & .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }

  & .headroom--scrolled {
    background-color: #ffffff;
    box-shadow: headroomBoxShadow;
    transition: transform 200ms ease-in-out;

    a {
      color: ${props => props.theme.colors.text} !important;
      text-shadow: none;
    }
  }

  & .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }

  & .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
`;

const StyledHeader = styled.header`
  padding: ${props => props.theme.header.padding.vertical} 0;
  background-color: ${props => (props.ishome ? 'transparent' : '#ffffff')};
  border-top: 3px solid ${props => props.theme.colors.accent};
  z-index: 20;
  top: 0;
  right: 0;
  left: 0;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
  }
`;

const MenuToggle = styled.button`
  position: relative;
  width: 50px;
  height: 50px;
  padding: 1rem;
  background-color: #FFFFFF;
  border: 1px solid ${props => props.theme.colors.text};
  border-bottom-width: 3px;
  cursor: pointer;
  outline: none;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 20%;
    width: 60%;
    height: 2px;
    background-color: ${props => props.theme.colors.text};
    transform-origin: 50%;
    transition:
      ${props => (props.isMenuOpen ? 'transform 100ms ease-in-out 100ms' : 'transform 100ms ease-in-out')},
      ${props => (props.isMenuOpen ? 'top 100ms ease-in-out' : 'top 100ms ease-in-out 100ms')};
  }

  &:before {
    top: ${props => (props.isMenuOpen ? '50%' : '40%')};
    transform: rotate(${props => (props.isMenuOpen ? '45deg' : '0')});
  }

  &:after {
    top: ${props => (props.isMenuOpen ? '50%' : '60%')};
    transform: rotate(${props => (props.isMenuOpen ? '-45deg' : '0')});
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    display: none;
  }
`;


class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
    };
  }

  toggleMenu() {
    this.setState(state => ({
      isMenuOpen: !state.isMenuOpen,
    }));
  }

  render() {
    const { title, data, ishome = false } = this.props;
    const { isMenuOpen } = this.state;

    return (
      <StyledHeadroom disableInlineStyles ishome={ishome && true} isMenuOpen>
        <StyledHeader ishome={ishome}>
          <Container>
            <Nav>
              <Logo logo={logo} title={title} data={data} />

              <MenuToggle
                type="button"
                isMenuOpen={isMenuOpen}
                onClick={() => this.toggleMenu()}
                aria-label={isMenuOpen ? 'Skrýt menu' : 'Zobrazit menu'}
              >
                &nbsp;
              </MenuToggle>

              <Navbar isOpen={isMenuOpen} />
            </Nav>
          </Container>
        </StyledHeader>
      </StyledHeadroom>
    );
  }
}

export default Header;
